import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"

// import SEO from "../../components/common/seo"

import Layout from "../../components/promotions/layout"

const PartnersPage = ({data, location}) => {

  useEffect(() => {
    // just redirect to the community one
    navigate('/partners/sponsor/')
  }, [])

  return ''
}


export default PartnersPage
